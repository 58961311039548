/*Connection alert*/

@-webkit-keyframes blinking {
    0% {
        color: #ff3d50;
    }
    /* YOU CAN ADD MORE COLORS IN THE KEYFRAMES IF YOU WANT
      50%{
        color: #55d66b;
      }
      */
    100% {
        color: #999;
    }
}

@keyframes blinking {
    0% {
        color: #ff3d50;
    }
    /* YOU CAN ADD MORE COLORS IN THE KEYFRAMES IF YOU WANT
      50%{
        color: #55d66b;
      }
      */
    100% {
        color: #999;
    }
}

.connection_alert {
    -webkit-animation: blinking 1s infinite;
            animation: blinking 1s infinite;
    margin: 0 50px;
}
/*CUSTOM STYLES*/

.navbar_h {
    background: url(/logo.8ca8c112.png) no-repeat;
    width: 228px;
    height: 20px;
}

.main_navbar {
    background-color: #2d5f7e !important;
}



.community_navbar {
    background-color: #596974 !important;
}

.community_navbar .bp3-button, .community_navbar .bp3-icon {
    color: #fff !important;
}

/*
.community_title {
    color: #000 !important;
}
*/

.communication {
    background-color: #e5e8eb !important;
}

/*
::-webkit-scrollbar {
    width: 5px
}

::-webkit-scrollbar-track {
    background: #f1f4f5;
    border-radius: 0px
}

::-webkit-scrollbar-thumb {
    background: #bec7c9;
    border-radius: 2px
}
*/
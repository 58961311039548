.community_title {
    font-family: 'Baloo Bhai', cursive;
    text-shadow: 0px 1px 1px #4d4d4d;
    color: rgb(255, 255, 255);
}

.community_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
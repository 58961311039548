.wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 100vw;
    margin: auto;
    min-height: 100vh;
}

.login {
    background-color: #394b59;
    color: #fff;
    padding: 10px 0;
    height: 100vh;
}

.login_container {
    margin: 20px;
    max-width: 300px;
}

.no_mobile {
    text-align: center;
    width: 100%;
    margin: 10px 0;
    color: rgb(155, 9, 9);
    font-weight: bold;
}

/*CUSTOM STYLES*/

.main_navbar {
    background-color: #394b59 !important;
}

.navbar_h {
    background: url(/logo.6ad47802.png) no-repeat;
    width: 145px;
    height: 20px;
}

.community_navbar {
    background-color: #889da8 !important;
}

.community_title {
    color: #ffffff !important;
}

.communication {
    background-color: #f1f4f5 !important;
}

::-webkit-scrollbar {
    width: 5px
}

::-webkit-scrollbar-track {
    background: #f1f4f5;
    border-radius: 0px
}

::-webkit-scrollbar-thumb {
    background: #bec7c9;
    border-radius: 2px
}